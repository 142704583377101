<template> 
  <Layout ref="main">
    <div class="container">
      <div class="row mb-5">
        <div class="col-12 px-0">
          <b-tabs
              justified
              nav-class="nav-tabs-custom2 mb-3"
              content-class="text-muted"
              @activate-tab="tabChange"
              v-model="tabSelected"
            >
              <b-tab>
                <template v-slot:title>
                  {{$t('siteLang.MyLoan')}}
                </template>
                <div class="tabcontent">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-block text-center my-5" v-if="loading">
                          <div class="spinner-border mb-2" role="status">
                              <span class="sr-only">Loading...</span>
                          </div>
                          <div>
                          Loading..
                          </div>
                      </div>
                      <div class="card p-3 text-muted mb-3" v-if="!loading && !loanList.length">
                        <div class="row align-items-center ">
                          <div class="col-auto text-center">
                            <div class="avatar-xs">
                                  <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                      <i class="mdi mdi-file-document font-size-18"></i>
                                  </span>
                             </div>
                          </div>
                          <div class="col-auto">{{$t('siteLang.LoanListEmpty')}}</div>
                        </div>
                      </div>
                      <div v-if="!loading">
                      <div class="card p-3 text-muted mb-3" v-for="(value, index) in loanList" :key="index">
                        <router-link :to="{ name: 'loan detail', params: { id: value.order_id }}" class="text-muted">
                        <div class="row align-items-center ">
                          <div class="col-auto text-center pe-1">
                            <span v-if="value.status=='0'"> <i class="mdi mdi-check-circle text-muted-custom font-size-24"></i></span>
                            <span v-else-if="value.status=='1'"> <i class="mdi mdi-check-circle text-success font-size-24"></i></span>
                            <span v-else-if="value.status=='2'"><i class="mdi mdi-alert-circle text-danger font-size-24"></i></span>
                          </div>
                          <div class="col">
                            <div>{{$t('siteLang.OrderNumber')}}: <span class="text-body ">{{value.order_number}}</span></div>
                            <div class="mt-1">{{$t('siteLang.LoanStatus')}}:  
                              <span v-if="value.status=='0'" class=" fw-medium">{{$t('siteLang.Pending')}}</span>
                              <span v-else-if="value.status=='1'" class="text-success fw-medium">{{$t('siteLang.Approved')}}</span>
                              <span v-else-if="value.status=='2'" class="text-danger fw-medium">{{$t('siteLang.Rejected')}}</span>
                            </div>
                          </div>
                          <div class="col text-end">
                            <strong class="d-block fw-medium">Rp. {{value.loan_amount}}</strong>
                            <strong class="d-block fw-medium">{{value.loan_term}} {{$t('siteLang.Months')}}</strong>
                          </div>
                        </div>
                        </router-link>
                      </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
               
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  {{$t('siteLang.MyWithdrawal')}} <i class="mdi mdi-alert-circle text-danger font-size-18" v-if="ifGotWithdrawalError()"></i>
                </template>
                <div class="tabcontent">
                  <div class="row">
                    <div class="col-12">
                      <div class="d-block text-center my-5"  v-if="loading2">
                          <div class="spinner-border mb-2" role="status">
                              <span class="sr-only">Loading...</span>
                          </div>
                          <div>
                          Loading..
                          </div>
                      </div>
                      <div class="card p-3 text-muted mb-3" v-if="!loading2 && !withdrawalList.length">
                        <div class="row align-items-center ">
                          <div class="col-auto text-center">
                            <div class="avatar-xs">
                                  <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                                      <i class="mdi mdi-cash-usd font-size-18"></i>
                                  </span>
                             </div>
                          </div>
                          <div class="col-auto">{{ $t('siteLang.WithdrawalListEmpty') }}</div>
                        </div>
                      </div>
                      
                      <div v-if="!loading2">
                      <div class="card p-3 text-muted mb-3" :class="{'cursor-pointer':value.status==2}" v-for="(value, index) in withdrawalList" :key="index" @click="toggleShowHide(index)">
                      
                        <div class="row align-items-center ">
                          <div class="col-auto text-center pe-1">
                            <span v-if="value.status=='2'"> <i class="mdi mdi-alert-circle text-danger font-size-24"></i></span>
                            <span v-if="value.status=='0'"> <i class="mdi mdi-check-circle text-muted-custom font-size-24"></i></span>
                            <span v-else-if="value.status=='1'"> <i class="mdi mdi-check-circle text-success font-size-24"></i></span>
                          </div>
                          <div class="col">
                            <div>{{value.date}}</div>
                            <div class="mt-1">{{$t('siteLang.WithdrawalStatus')}}:
                              <span v-if="value.status=='0'" class="fw-medium">{{$t('siteLang.Pending')}}</span>
                              <span v-else-if="value.status=='1'" class="text-success fw-medium">{{$t('siteLang.Approved')}}</span>
                              <span v-else-if="value.status=='2'" class="text-danger fw-medium">{{$t('siteLang.Rejected')}} </span>
                            </div>
                          </div>
                         
                             
                          
                          <div class="col text-end">
                            <strong class="d-block fw-medium">{{$t('siteLang.WithdrawalAmount')}}</strong>
                            <strong class="d-block fw-medium">Rp. {{value.withdraw_amount}}</strong>
                          </div>
                          <div class="col-12 pt-2 mt-2 border-top" v-if="value.status=='2' && toggle[index]" :class="{'animate__animated animate__shakeX' : toggle[index]}">
                            <label for="reason" class="form-label text-body">{{ $t('siteLang.RejectReason') }} <i class="mdi mdi-alert-circle-outline font-size-16 text-danger"></i></label>
                          <textarea class="form-control m-0" rows="3" disabled v-model="value.reason"></textarea>
                          </div>
                        </div>
                      </div>
                      </div>
                    
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
        </div>
      </div>
      <div class="row align-items-center justify-content-center mb-0">
      <div class="col-auto text-center px-0">
        <img src="@/assets/images/app/account-safe.png" height="18"> 
      </div>
      <div class="col-auto text-center px-1">
       {{$t('siteLang.AccountFundSecurity')}}
      </div>
    </div>
    </div>
    <Lottie :path="'animate/payment.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
//import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
const CancelToken = axios.CancelToken;
let cancel;
/**
 * Starter page
 */

export default {
  components: { 
    Layout, 
    Lottie,
    Common
  },
  page() {
    return {
      title: this.$t('siteLang.MyBill'),
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      prevRoute:{
        query:{
           status:""
        },
      },
      needReload:false,
      session: null ,
      fullData:{},
      nextRoute:"",
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.title,
          href: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      loanList: [],
      withdrawalList: [],
      toggle: [],
      loading:false,
      loading2:false,
      tabSelected:1
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })

  },
  watch: {
    '$route.query': {
      immediate: true, // This will trigger the watcher immediately on component mount
      handler(newQuery, oldQuery) {
        // Handle the query change here
        console.log('New query:', newQuery);
        console.log('Old query:', oldQuery);
        console.log('newQuery.reload', newQuery.reload);
        if (newQuery.reload !==undefined){
          console.log("call me reload")
          this.tabSelected =1
          if (this.tabSelected==0){
            this.tabSelected = 0
            this.getData()
          }else{
            this.tabSelected = 1
            this.getData2()
          }
         
          this.needReload=true
        }else{
          this.needReload=false
        }
      }
    }
  },
  mounted(){
    
    //  this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    this.$refs.main.changeVerticalTopBar("",true)
    this.$refs.main.updateBalance("0.00")
    this.$refs.main.updateCard4Digits("----")
    this.$refs.main.setShowFooterCert(true)
    this.fullData = this.$refs.commonFunc.getFullData()
    this.$refs.main.updateBalance(this.fullData.withdrawal_amount || "0.00")
    this.$refs.main.updateCard4Digits(this.fullData.bank_account)
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    const urlParams = new URLSearchParams(window.location.search);
    const pValue = urlParams.get('p');
    if (pValue=='withdrawal') {
      this.getData2()
    }else{
      this.getData()
    }
   
  },
  beforeMount(){
  },
  created(){
    const urlParams = new URLSearchParams(window.location.search);
    const pValue = urlParams.get('p');
    if (pValue=='withdrawal') {
      this.tabSelected = 1
    }else{
      this.tabSelected = 0
    }
  },
  beforeRouteLeave(to, from, next) {
    next(vm => {
      vm.nextRoute = to
    })
    console.log('ready to leave')
    cancel('')
  },
  
  methods:{
    toggleShowHide(index){
      this.toggle.splice(index,1,!this.toggle[index]);
    },
    IwantUpdateBalance(){
      this.$refs.main.updateBalance("19,000,000")
    },
    tabChange:function(tabIndex) {
      //console.log(index)
      const currentQuery = new URLSearchParams(this.$route.query)
      if (tabIndex==0){
        this.getData()
        currentQuery.set('p', 'loan');
      }else if (tabIndex==1){
        this.getData2()
        currentQuery.set('p', 'withdrawal');
      }
      const queryObject = Object.fromEntries(currentQuery.entries());
      this.$router.replace({ query: queryObject })
    },
    getData(){
      if(cancel !== undefined) cancel('');
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone",  this.accessPhone);
      axios({
          method: "post",
          url: appConfig.APIHost + "controller/webapp/getLoanList",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c),
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData.data.loan_list)
            this.loanList = []
            const withdraw_amount = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(resData.data.withdrawal_amount))
            //resData.data.withdrawal_amount
            //getDataAndUpdateLocalStorage
           

            var data = []
            var loanStatus = 99
            resData.data.loan_list.forEach(value => {
              const loan_amount = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(value.loan_amount)).replaceAll(".00","")
              data.push({
                order_id: value.order_id,
                order_number: value.order_number,
                status: value.status,
                loan_amount: loan_amount,
                loan_term: value.loan_term,
                date:value.date,
                monthly_repayment:value.monthly_repayment
              })
            });
            //get latest status
            if(resData.data.loan_list.length>0){
              loanStatus = data[0].status
            }
            
            this.loanList = data
            console.log("loanStatus>"+loanStatus)
            const updateData = {
              "withdrawal_amount": withdraw_amount,
              "bank_account_name": resData.data.bank_account_name,
              "bank_account": resData.data.bank_account,
              "loanStatus": loanStatus
            }
            this.$refs.commonFunc.updateData(JSON.stringify(updateData))
             //updateDataIntoView
            this.$refs.main.updateBalance(updateData.withdrawal_amount || "0.00")
            this.$refs.main.updateCard4Digits(updateData.bank_account)

            // var data = [
            
            //   {
            //     order_id: "1",
            //     order_number: "202302141235",
            //     status: "1",
            //     loan_amount: "1,000,000",
            //     loan_term: "3",
            //     date:"2023-02-14 12:33:23",
            //     monthly_repayment:"333,750"
            //   },
            //   {
            //     order_id: "2",
            //     order_number: "202302141235",
            //     status: "2",
            //     loan_amount: "1,000,000",
            //     loan_term: "3",
            //     date:"2023-02-14 12:33:23",
            //     monthly_repayment:"333,750"
            //   },
            //   {
            //     order_id: "3",
            //     order_number: "202302141234",
            //     status: "0",
            //     loan_amount: "1,000,000",
            //     loan_term: "3",
            //     date:"2023-02-14 12:33:23",
            //     monthly_repayment:"333,750"
            //   },
            // ]
            // this.loanList = data
            this.deleteParams('reload')
          }
          else if (resData.status == 401){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading = false;
          this.$Progress.finish();
          console.log(error)
      });
    },
    getData2(){
      if(cancel !== undefined) cancel('');
      this.$Progress.start();
      this.loading2 = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("phone",  this.accessPhone);
      axios({
          method: "post",
          url:  appConfig.APIHost + "controller/webapp/getWithdrawList",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c),
      })
      .then((response)=>{
          var resData = response.data;
          if (resData.status == 200) {
            this.withdrawalList = []
            console.log(resData)
            //var data = []
            //this.$refs.main.updateBalance("1,000,000")
            
            const withdraw_amount = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(resData.data.withdrawal_amount))
            //getDataAndUpdateLocalStorage
            const updateData = {
              "withdrawal_amount": withdraw_amount,
              "bank_account_name": resData.data.bank_account_name,
              "bank_account": resData.data.bank_account
            }
            this.$refs.commonFunc.updateData(JSON.stringify(updateData))
             //updateDataIntoView
            this.$refs.main.updateBalance(updateData.withdrawal_amount || "0.00")
            this.$refs.main.updateCard4Digits(updateData.bank_account)


            var data = []
            resData.data.withdrawal_list.forEach(value => {
              const withdrawAamount = this.$refs.commonFunc.convertCurrencyFormat(parseFloat(value.withdraw_amount)).replaceAll(".00","")
              data.push({
                id: value.id,
                date: value.date,
                status: value.status,
                withdraw_amount: withdrawAamount,
                reason: value.reason,
              })
              this.toggle.push(true)
            });
            this.withdrawalList = data
            // var data = [
            //   {
            //     id: "",
            //     date: "2023-02-14 23:22",
            //     status: "1",
            //     withdraw_amount: "21,000,000",
            //     reason:""
            //   },
            //   {
            //     id: "2",
            //     date: "2023-02-14 23:22",
            //     status: "0",
            //     withdraw_amount: "1,000,000",
            //     reason:""
            //   },
            //   {
            //     id: "0",
            //     date: "2023-02-14 23:22",
            //     status: "2",
            //     withdraw_amount: "1,000,000",
            //     reason:` Unfortunately, we were unable to process your request due to a document mismatch issue.`
            //   },
            // ]
            // data.forEach(element => {
            //   console.log(element)
            //   this.toggle.push(false)
            // });
            // this.withdrawalList = data
            //this.removeQueryParam()
            this.deleteParams('reload')
          } 
          else if (resData.status == 401){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading2 = false;
          this.$Progress.finish();
      })
      .catch((error)=> {
          this.loading2 = false;
          this.$Progress.finish();
          console.log(error)
      });
    },
    ifGotWithdrawalError(){
      if (this.withdrawalList.length>0){
        if (this.withdrawalList[0].status == 2){
          return true
        }else{
          return false
        }
      }
      // const Failed = this.withdrawalList.some(obj => obj.status === "2");
      // return Failed
    },
    deleteParams(param){
      let params = new URLSearchParams(window.location.search);
      params.delete(param); //Query string is now: 'bar=2'
      window.history.replaceState({}, '', `${location.pathname}?${params.toString()}`);
      
      if (this.needReload){
        // Get the current query string and create a new URLSearchParams object
          const currentQuery = new URLSearchParams(this.$route.query)
          // Remove the 'reload' parameter from the object
          currentQuery.delete('reload')
          const queryObject = Object.fromEntries(currentQuery.entries());
          // Replace the current URL with the updated query string
          this.$router.replace({ query: queryObject })
      }
      
      
   
    }
  },
  middleware: "authentication",
};
</script>
<style scoped>
.tabcontent{
  min-height: 280px;
}
</style>
<style lang="scss">
.nav-tabs-custom2 {
  border-bottom: 1px solid #D1D3D4;

  .nav-item {
    position: relative;
    .nav-link {
      border: none;
      padding:10px 0px 12px 0px;
      font-weight: normal;
      font-size:15px;
      color: #939393 ;
      &::after {
        content: "";
        background: #04DA8C;
        height: 5px;
        position: absolute;
        width:60%;
        left: 20%;
        bottom: -3px;
        transition: all 250ms ease 0s;
        transform: scale(0);
        border-radius: 2px;
      }

      &.active{
        color: #04DA8C;
        font-weight: 400;
        background: none;
        &:after{
          transform: scale(1);
        }
      }

    }
  }
}
.text-muted-custom{
  color: #D1D3D4;
}
.cursor-pointer{
  cursor: pointer;
}
</style>